@if (requiredProportion) {
  <div
    class="row pb-2"
    [class]="
      proportionValidation
        ? 'text-proportion danger'
        : ' text-proportion warning'
    "
  >
    <div class="col-1" style="width: 1%">
      <mat-icon class="info-icon">info</mat-icon>
    </div>
    <div class="col-3">
      <label>{{ requiredProportionText }}</label>
    </div>
  </div>
}
<div class="row">
  @if ((!isMultiple && uploadedImages.length === 0) || isMultiple) {
    <div [class]="dropzoneDivClass">
      <ngx-dropzone
        (change)="onSelect($event)"
        class="custom-dropzone"
        [multiple]="isMultiple"
      >
        <ngx-dropzone-label class="dropzone-label">
          <mat-icon class="dropzone-icon">add_circle</mat-icon>
          <div class="row" style="position: relative">
            <span> {{ 'Add Image' | translate }} </span>
          </div>
        </ngx-dropzone-label>
      </ngx-dropzone>
    </div>
  }
  <div class="col-10 pt-4">
    <div class="row">
      @for (image of uploadedImages; track $index) {
        <div class="pe-2 pb-2" style="width: auto">
          <img [src]="image.url" class="dropzone-image" />
          <div class="remove-image" (click)="removeImage(image.id)">
            <button mat-button class="remove-button">
              <mat-icon class="remove-image-icon">delete</mat-icon
              >{{ 'Remove Image' | translate }}
            </button>
          </div>
        </div>
      }
    </div>
  </div>
</div>
