import { MatDialog } from '@angular/material/dialog'
import { Utils } from '../../../../helpers/utils.helper'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { LoaderModalComponent } from '../loader-modal/loader-modal.component'
import { Store } from '@ngxs/store'
import { UserMetaState } from 'src/store/user/user.meta.state'
import { IFiles } from 'src/interfaces/files/files.interface'
import { MatIconModule } from '@angular/material/icon'
import { NgxDropzoneModule } from 'ngx-dropzone'
import { TranslateModule } from '@ngx-translate/core'
import { MatButtonModule } from '@angular/material/button'

@Component({
  standalone: true,
  imports: [MatIconModule, NgxDropzoneModule, TranslateModule, MatButtonModule],
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss'],
})
export class UploadPhotoComponent implements OnInit {
  @Input() key!: string
  @Input() isMultiple = true
  @Input() requiredProportion!: { width: number; height: number }
  @Input() proportionValidation = false
  @Input() uploadedImages: any[] = []
  @Input() dropzoneDivClass = 'col-3'
  @Output() uploadedImagesChange = new EventEmitter<IFiles[]>()
  @Output() deletedImage = new EventEmitter<IFiles[]>()

  requiredProportionText = ''

  constructor(
    private utils: Utils,
    private dialog: MatDialog,
    private store: Store,
  ) {}

  ngOnInit(): void {
    if (this.requiredProportion) {
      this.generateRequiredProportionText()
    }
  }

  generateRequiredProportionText(): void {
    if (this.proportionValidation) {
      this.requiredProportionText =
        'A proporção da imagem deve ser ' +
        this.requiredProportion.width +
        'x' +
        this.requiredProportion.height +
        '.'
    } else {
      this.requiredProportionText =
        'A proporção recomendada da imagem é ' +
        this.requiredProportion.width +
        'x' +
        this.requiredProportion.height +
        '.'
    }
  }

  onSelect(event: any) {
    const loader = this.dialog.open(LoaderModalComponent, {
      width: '500px',
      height: '300px',
      panelClass: 'main-modal',
      disableClose: true,
      data: 'Fazendo upload de imagem...',
    })
    const author = this.store.selectSnapshot(UserMetaState.getUser)
    const files = event.addedFiles
    const selectedFiles = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const url = URL.createObjectURL(file)
      const sanitizedFile = this.utils.sanitizeFile(url) as any
      const unsupported = this.isFileUnsupported(file)
      const randomStringId = this.utils.generateRandomString(10)
      this.uploadedImages.push({
        id: randomStringId,
        file,
        type: file.type,
        name: file.name,
        url: sanitizedFile,
        unsupported,
        author,
        attachmentType: 'IMAGE',
      })
    }
    this.uploadedImagesChange.emit(this.uploadedImages)
    console.log(this.uploadedImages)
    loader.close()
  }

  isFileUnsupported(file: any) {
    const availableFileTypes = ['pdf', 'image', 'video', 'audio']
    const splittedFileTypes = file.type.split('/')
    return (
      !availableFileTypes.includes(splittedFileTypes[0]) &&
      !availableFileTypes.includes(splittedFileTypes[1])
    )
  }

  removeImage(id: string) {
    const imageIndex = this.uploadedImages.findIndex((image) => image.id === id)
    if (imageIndex !== -1) {
      const deleted = this.uploadedImages.splice(imageIndex, 1)
      this.deletedImage.emit(deleted)
      this.uploadedImagesChange.emit(this.uploadedImages)
    }
  }
}
